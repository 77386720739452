// See BSMG-3163

import { type RouteLocation, createRouter, createWebHistory } from "vue-router"
import { LoginCallback, navigationGuard } from "@okta/okta-vue"
import {
  getOpenFeatureClient,
  addFeatureFlagListener,
} from "@justworkshr/frontend-launch-darkly-open-feature-integration"
import { setupLayouts } from "virtual:generated-layouts"
import generatedRoutes from "virtual:generated-pages"
import { useGlobalError } from "stella"
import { getViewer, loaded, isEmployeeRestricted } from "@/viewer"

declare module "vue-router" {
  interface RouteMeta {
    // must be declared by every route
    requiresAuth: boolean
    featureFlag?: string
    abilities?: string[]
  }
}

// Vite URL injected via secrets in github actions
const PAYROLL_PROVIDER_LOGIN = `${import.meta.env.VITE_PAYROLL_PROVIDER_URL}`

// TODO - this state is not global. Must revise useGlobalError and useApiFetch states
const { resetGlobalError } = useGlobalError(PAYROLL_PROVIDER_LOGIN)

const hasQueryParams = function hasQueryParams(route: RouteLocation) {
  return !!Object.keys(route.query).length
}

const urlParams = new URLSearchParams(window.location.search)
const requiresAuth = urlParams.has("target_member_id")
const targetMemberId = urlParams.get("target_member_id")
window.sessionStorage.setItem("target_member_id", targetMemberId ?? "")
window.sessionStorage.setItem("sso_auth_enabled", String(requiresAuth))

const authenticatedRoutes = generatedRoutes.map((route) => {
  route.meta = { requiresAuth, ...route.meta }

  return route
})

const routes = setupLayouts([
  {
    path: "/login/callback",
    component: LoginCallback,
    meta: { requiresAuth: false, noAuth: true },
  },
  ...authenticatedRoutes,
])

const router = createRouter({
  history: createWebHistory(),
  routes,
})

// eslint-disable-next-line @typescript-eslint/unbound-method
const originalPush = router.push

// This is a workaround for an error that is thrown in vue-router when
// a navigation guard redirects more than once.
// We need to catch this error and ignore it, so we can retain the
// query params from the original route.
router.push = function push(location) {
  // @ts-expect-error Incompatible types
  return originalPush.call(this, location).catch((err: Error) => {
    const reg = new RegExp(
      '^Redirected when going from "[a-z_.\\/]+" to "[a-z_.\\/]+" via a navigation guard.$'
    )
    if (reg.test(err.message)) {
      return Promise.resolve(false)
    }
    return Promise.reject(err)
  })
}

// Copies query params from the previous route to the next route
router.beforeEach((to, from, next) => {
  if (!hasQueryParams(to) && hasQueryParams(from)) {
    next({ ...to, query: { ...to.query, ...from.query } })
  } else {
    next()
  }
})

router.beforeEach(navigationGuard)

router.beforeEach(async (to, from, next) => {
  if (to.path == "/login/callback") return next()
  if (!to.meta.noAuth && !loaded.value) {
    await getViewer()
  }

  let isRouteDisabled = false

  if (to.meta.featureFlag) {
    isRouteDisabled = !getOpenFeatureClient().getBooleanValue(
      to.meta.featureFlag,
      false
    )
  }

  const routeRequiresAbilities = !!to.meta.abilities
  const isEmployeeRestrictedFromRoute = routeRequiresAbilities
    ? isEmployeeRestricted(to.meta.abilities)
    : false

  if (isRouteDisabled || isEmployeeRestrictedFromRoute) next(from)
  else next()
})

router.afterEach(() => {
  resetGlobalError()
})

// If a feature flag is turned off while someone is on the page,
// kick them off!
generatedRoutes
  .filter((route) => {
    return !!route.meta?.featureFlag
  })
  .forEach((route) => {
    addFeatureFlagListener(route.meta!.featureFlag!, (isEnabled) => {
      if (router.currentRoute.value.path.startsWith(route.path) && !isEnabled) {
        router.push({ path: "/" }).catch(console.error)
      }
    })
  })

export const loginRedirect = (payrollProviderUrl?: string) => {
  if (payrollProviderUrl) {
    void window.location.assign(`${payrollProviderUrl}`)
  } else {
    void window.location.assign(PAYROLL_PROVIDER_LOGIN)
  }
}

export default router
